var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',_vm._g(_vm._b({attrs:{"top":"5vh","center":"","width":"80%","append-to-body":true,"destroy-on-close":true,"close-on-click-modal":false,"title":"分配用户"}},'el-dialog',_vm.$attrs,false),_vm.$listeners),[_c('el-card',[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("姓名：")]),_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"请输入姓名"},on:{"input":_vm.queryList},model:{value:(_vm.ajaxParam.search),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "search", $$v)},expression:"ajaxParam.search"}})],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"pl-1"},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司名称：")]),_c('jobStatusRadioGroup',{attrs:{"recordRow":_vm.recordRow},on:{"change":function (){
                                _vm.ajaxParam.currentPage = 1;
                                _vm.queryList();
                            }},model:{value:(_vm.ajaxParam.companyId),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "companyId", $$v)},expression:"ajaxParam.companyId"}})],1)]),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("是否为公司管理员：")]),_c('el-select',{attrs:{"size":"mini","clearable":""},on:{"change":function($event){return _vm.queryList()}},model:{value:(_vm.ajaxParam.isCompanyAdmin),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "isCompanyAdmin", $$v)},expression:"ajaxParam.isCompanyAdmin"}},[_c('el-option',{attrs:{"value":0,"label":"是"}},[_vm._v("是")]),_c('el-option',{attrs:{"value":1,"label":"否"}},[_vm._v("否")])],1)],1),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("是否部门管理员：")]),_c('el-select',{attrs:{"size":"mini","clearable":""},on:{"change":function($event){return _vm.queryList()}},model:{value:(_vm.ajaxParam.isDepartManager),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "isDepartManager", $$v)},expression:"ajaxParam.isDepartManager"}},[_c('el-option',{attrs:{"value":0,"label":"是"}},[_vm._v("是")]),_c('el-option',{attrs:{"value":1,"label":"否"}},[_vm._v("否")])],1)],1)],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"between"}},[_c('el-col',[_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":_vm.batchRecordRows.length===0?true:false},on:{"click":function($event){return _vm.batAssign(0)}}},[_vm._v("批量分配")]),_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":_vm.batchRecordRows.length===0?true:false},on:{"click":function($event){return _vm.batAssign(1)}}},[_vm._v("取消批量分配")])],1),_c('el-col',{attrs:{"span":1}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function (){
                                _vm.$refs.table.clearCheckboxRow();
                                _vm.batchRecordRows=[];
                                _vm.ajaxParam.companyId = null;
                                _vm.ajaxParam.isCompanyAdmin = null;
                                _vm.ajaxParam.isDepartManager = null;
                                _vm.ajaxParam.search = null;
                                _vm.ajaxParam.currentPage = 1;
                                // ajaxParam.pageSize = 20;
                                        _vm.queryList();
                                    }}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"title","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"500","data":_vm.records},on:{"checkbox-change":_vm.checkboxChange,"checkbox-all":_vm.checkboxChange}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-table-column',{attrs:{"type":"seq","title":"姓名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.realName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"公司名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.companyName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"是否为公司管理员","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.isCompanyAdmin===0?"是":"否")+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"是否为部门管理员","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.isDepartManager===0?"是":"否")+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"所属角色","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.roleName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"分配状态","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.assignName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"设置分配","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_c('el-switch',{attrs:{"active-color":"#13ce66","active-value":0,"inactive-value":1},on:{"change":function($event){return _vm.singleAssign(row)}},model:{value:(row.assign),callback:function ($$v) {_vm.$set(row, "assign", $$v)},expression:"row.assign"}})]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.ajaxParam.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.ajaxParam.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":function (pageSize){_vm.ajaxParam.pageSize=pageSize;_vm.queryList()},"current-change":function (currentPage){_vm.ajaxParam.currentPage=currentPage;_vm.queryList()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }