<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row>
			<el-col :span="4"><div style="width: 1px;height: 1px;"></div></el-col>
			<el-col :span="20">
				<el-radio-group size="mini"  v-model="radio" @change="changeRadio">
					<el-radio-button label="1">解耦</el-radio-button>
					<el-radio-button label="2">未解耦</el-radio-button>
				</el-radio-group>
			</el-col>
		</el-row>
        <div style="height: 20px"></div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="110px" class="allForm">
					<el-form-item label="权限列表" :required="true">
						<!-- <el-cascader ref="asasas" v-model="form.permissionIds" :options="permission" filterable clearable class="formInput w-100" -->
						<!-- :props="{lazy:false,value:'id',children:'sons',label:'permissionsName',checkStrictly:true,emitPath:false,multiple: true}" /> -->

						<el-tree ref="tree" show-checkbox :data="permission" :check-strictly="checkStrictly" node-key="id"
						 :expand-on-click-node="false" @check-change="handleCheckChange" :props="{children:'sons',label:'permissionsName'}">
						</el-tree>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button size="small" type="primary" @click="commit">提交</el-button>
			<el-button size="small" type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		permissions,
		role,
		rolePermission
	} from '@/api'
	export default {
		name: 'Permission',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					permissionIds: []
				},
				permission: [],
				expandTree: true,
				checkStrictly: true,
				radio: 1
			}
		},
		created() {
			if (this.info.addOr === 'permiApp') {
				this.getPermissionApp()
			} else {
				this.getPermission()
			}
		},
		mounted() {
			this.getRole()
		},
		methods: {
			changeRadio(){
				if (this.radio == 1) {
					this.checkStrictly = true;
				} else{
					this.checkStrictly = false;
				}
			},
			getPermission() {
				const arr = []
				if (!this.row.isDefault == 0) {
					for (const i of this.row.companyIds) {
						arr.push(i.companyId)
					}
				}
				this.$axiosReq(permissions, null, {
					search: '',
					statu: 0,
					type: 1,
					companyId: this.row.isDefault == 0 ? '' : arr.join()
				}, 'get').then(res => {
					this.permission = res.data
				})
			},
			getPermissionApp() {
				this.$axiosReq('/oauth/server/escort/web/v2.5/appPermissions', null, {
					search: '',
					statu: 0,
					type: 1,
					pageSize: 10000,
					companyId: this.$admin ? null : this.row.id
				}, 'get').then(res => {
					this.permission = res.data
				})
			},
			getRole() {
				this.$axiosReq(role + this.row.id, null, null, 'get').then(res => {
					this.form.permissionIds = [];
					if (this.info.addOr === 'permiApp') {
						this.form.permissionIds = res.data.appPermissionsList
					} else {
						this.form.permissionIds = res.data.permissionsList
					}
					if (this.form.permissionIds && this.form.permissionIds.length > 0) {
						this.$refs.tree.setCheckedKeys(this.form.permissionIds)
					}
				})
			},
			handleCheckChange() {
				let aaa = this.$refs.tree.getHalfCheckedKeys();
				this.form.permissionIds = this.$refs.tree.getCheckedKeys();
				this.form.permissionIds = this.form.permissionIds.concat(aaa)
				console.log(this.form.permissionIds)
			},
			// 提交添加
			commit() {
				if (this.form.permissionIds.length === 0) {
					this.$message.error('请选择权限！')
					return
				}
				if (this.info.addOr === 'permiApp') {
					this.$axiosReq('/oauth/server/escort/web/role/AppPermission/' + this.row.id, this.form.permissionIds, null, 'put')
						.then(res => {
							this.$message.success(res.msg)
							this.close()
							this.$emit('get-list')
						})
				} else {
					this.$axiosReq(rolePermission + this.row.id, this.form.permissionIds, null, 'put').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
.el-form{
    margin-left: 20px;
}
</style>
