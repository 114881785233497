<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<Permission v-if="dialog.refresh2" :dialog.sync="dialog.show2" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between" :gutter="50" :span="6">
				<el-col>
					<el-input style="width: 200px" v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="searchInput" />
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button v-if="$hasBtn('sys:permissions:org:role:add')" type="primary" size="mini" @click="openDialog({title:'添加角色',addOr:'add'})">添加角色</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="job_id" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column title="公司名称" show-overflow="title">
						<template v-slot="{row}">
							<span v-for="(v,i) in row.companyIds" :key="i">{{ row.companyIds.length===1?v.companyName:v.companyName+',' }}</span>
							<!-- <div class="companyH">
								<span v-for="(v,i) in row.companyIds" :key="i">{{ row.companyIds.length===1?v.companyName:v.companyName+',' }}</span>
							</div> -->
						</template>
					</vxe-table-column>
					<vxe-table-column field="roleName" title="角色名称" show-overflow="title" />
					<vxe-table-column field="roleCode" title="角色编码" show-overflow="title" />
					<vxe-table-column title="系统配置">
						<template v-slot="{row}">
							<span v-if="row.isDefault===0">是</span>
							<span v-else>否</span>
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:role:edit')" :disabled="!$admin&&row.isDefault===0" icon="el-icon-edit"
									 @click.native="openDialog({title:'修改角色',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:role:permissions')" :disabled="!$admin&&row.isDefault===0"
									 icon="el-icon-link" @click.native="openDialog2({title:'分配web权限',addOr:'permi'},row)">分配web权限</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:role:permissions')" :disabled="!$admin&&row.isDefault===0"
									 icon="el-icon-link" @click.native="openDialog2({title:'分配app权限',addOr:'permiApp'},row)">分配app权限</el-dropdown-item>

                                    <el-dropdown-item icon="el-icon-link" @click.native="recordRow=row;showAllocationUser=true;">分配用户</el-dropdown-item>

									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:role:delete')" :disabled="!$admin&&row.isDefault==0" icon="el-icon-delete"
									 @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>

        <AllocationUser @close="()=>{}" v-if="showAllocationUser" :visible.sync="showAllocationUser" :recordRow="recordRow"/>
	</div>
</template>

<script>
import AllocationUser from "./components/AllocationUser.vue";
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		role
	} from '@/api'
	import Operate from './Operate'
	import Permission from './Permission'
	export default {
		name: 'Index',
		components: {
			Permission,
			Operate,
            AllocationUser
		},
		data() {
			return {
                recordRow:null,
                showAllocationUser:false,

				loading: false,
				condition: {
					companyId: this.$admin ? null : this.$companyId,
					search: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					show: false,
					show2: false
				},
				company: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openDialog2(info, row) {
				this.dialog.refresh2 = true
				this.dialog.show2 = true
				this.dialog.info = info
				this.dialog.row = row
			},
			searchInput(v) {
				this.table.currentPage = 1
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq(role, null, {
					companyId: this.condition.companyId,
					roleName: this.condition.search,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq(role + '/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList(this.condition.company)
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.dialog.refresh2 = false
			}
		}
	}
</script>

<style scoped>
	.companyH {
		/* height: 80px; */
		overflow-y: scroll;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.companyH::-webkit-scrollbar {
		display: none;
	}
</style>
