<template>
    <el-dialog top="5vh" center
            v-bind="$attrs" v-on="$listeners"
            width="80%"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            title="分配用户"
    >
        <el-card>
            <el-row type="flex">
                <el-col :span="6">
                    <span class="text-primary text-pad-right">姓名：</span>
                    <el-input v-model="ajaxParam.search" size="mini"
                              class="input searchInput" suffix-icon="el-icon-search"
                              placeholder="请输入姓名" @input="queryList" style="width: 250px;" />
                </el-col>
                <el-col :span="6">
                    <div class="pl-1">
                        <span class="text-primary text-pad-right">公司名称：</span>
                        <jobStatusRadioGroup :recordRow="recordRow" v-model="ajaxParam.companyId" @change="()=>{
                                ajaxParam.currentPage = 1;
                                queryList();
                            }"/>
                    </div>
                </el-col>

                <el-col :span="6">
                    <span class="text-primary text-pad-right">是否为公司管理员：</span>
                    <el-select  v-model="ajaxParam.isCompanyAdmin" size="mini"
                        @change="queryList()" clearable
                    >
<!--                        <el-option :value="null" label="全部">全部</el-option>-->
                        <el-option :value="0"  label="是">是</el-option>
                        <el-option :value="1"  label="否">否</el-option>
                    </el-select>
                </el-col>

                <el-col :span="6">
                    <span class="text-primary text-pad-right">是否部门管理员：</span>
                    <el-select v-model="ajaxParam.isDepartManager"  size="mini"
                                    @change="queryList()" clearable
                    >
<!--                        <el-option :value="null" label="全部">全部</el-option>-->
                        <el-option :value="0" label="是">是</el-option>
                        <el-option :value="1" label="否">否</el-option>
                    </el-select>

                </el-col>
            </el-row>
        </el-card>

        <el-card  style="margin-top: 20px;">

            <vxe-toolbar>
                <template v-slot:buttons>
                    <el-row type="flex" justify="between">
                        <el-col>
                            <el-button type="primary" size="mini"
                                       :disabled="batchRecordRows.length===0?true:false"
                                @click="batAssign(0)"
                            >批量分配</el-button>
<!--                            0 分配 1 取消分配-->
                            <el-button type="primary" size="mini"
                                       :disabled="batchRecordRows.length===0?true:false"
                                @click="batAssign(1)"
                            >取消批量分配</el-button>
                        </el-col>
                        <el-col :span="1">
                            <el-button type="primary" size="mini" @click="()=>{
                                $refs.table.clearCheckboxRow();
                                batchRecordRows=[];
                                ajaxParam.companyId = null;
                                ajaxParam.isCompanyAdmin = null;
                                ajaxParam.isDepartManager = null;
                                ajaxParam.search = null;
                                ajaxParam.currentPage = 1;
                                // ajaxParam.pageSize = 20;
                                        queryList();
                                    }">刷新</el-button>
                        </el-col>
                    </el-row>
                </template>
            </vxe-toolbar>

            <vxe-table
                    ref="table"
                    border
                    resizable
                    show-overflow="title"
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyleFn"
                    align="center"
                    class="vxe-table-element"
                    height="500"
                    :data="records"
                    @checkbox-change="checkboxChange"
                    @checkbox-all="checkboxChange"
            >
                <vxe-table-column type="checkbox" width="60" fixed="left" />

                <vxe-table-column type="seq" title="序号" width="60" >
<!--                    <template  v-slot="{ row, rowIndex }">-->
<!--                        {{row }}-->
<!--                    </template>-->
                </vxe-table-column>

                <vxe-table-column type="seq" title="姓名" width="120">
                    <template  v-slot="{ row, rowIndex }">
                        {{row.realName }}
                    </template>
                </vxe-table-column>


                <vxe-table-column type="seq" title="公司名称" >
                    <template  v-slot="{ row, rowIndex }">
                        {{row.companyName }}
                    </template>
                </vxe-table-column>

                <vxe-table-column type="seq" title="是否为公司管理员" width="140">
                    <template  v-slot="{ row, rowIndex }">
                        {{row.isCompanyAdmin===0?"是":"否" }}
                    </template>
                </vxe-table-column>

                <vxe-table-column type="seq" title="是否为部门管理员" width="140">
                    <template  v-slot="{ row, rowIndex }">
                        {{row.isDepartManager===0?"是":"否" }}
                    </template>
                </vxe-table-column>

                <vxe-table-column type="seq" title="所属角色" show-overflow="title" >
                    <template  v-slot="{ row, rowIndex }">
                        {{row.roleName }}
                    </template>
                </vxe-table-column>
                <vxe-table-column type="seq" title="分配状态" width="80">
                    <template  v-slot="{ row, rowIndex }">
                        {{row.assignName }}
                    </template>
                </vxe-table-column>

                <vxe-table-column type="seq" title="设置分配" width="80">
                    <template  v-slot="{ row, rowIndex }">
                    <!--设置分配(0是1否)-->
                        <el-switch  @change="singleAssign(row)"
                                v-model="row.assign"  active-color="#13ce66"
                                :active-value="0"
                                :inactive-value="1">
                        </el-switch>

                    </template>
                </vxe-table-column>

            </vxe-table>

            <div style="height: 20px" />
            <el-pagination background :current-page="ajaxParam.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
                           :page-size="ajaxParam.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                           @size-change="(pageSize)=>{ajaxParam.pageSize=pageSize;queryList()}"
                           @current-change="(currentPage)=>{ajaxParam.currentPage=currentPage;queryList()}" />
        </el-card>
    </el-dialog>
</template>

<script>
import jobStatusRadioGroup from "./jobStatusRadioGroup.vue"
export default {
    components:{jobStatusRadioGroup},
    name: "AllocationUser",
    props:{
        recordRow:{
            type:Object,
            default:()=>null
        }
    },
    data(){
        return{
            ajaxParam:{
                companyId:null,
                currentPage:1,
                pageSize:20,
                // id:null,       // 角色id
                isCompanyAdmin:null,  // 是否公司管理员(0是、1否)
                isDepartManager:null,   // 是否是部门管理员(0是、1否)
                search:null,    //搜索
                // direction:"ASC" //排序方式，默认降序 DESC, ASC
                // property:""   // 排序字段，默认创建时间
            },

            records:[],
            total:0,

            row: null, //要操作的列表选中的一项  当前要操作的行
            batchRecordRows:[], //批量操作的数据
        }
    },
    created() {
        this.queryList();
    },
    methods:{
        async queryList(){
         let {code,data} =   await  this.$api.get(`/oauth/server/escort/web/role/assignUser/${this.recordRow.id}`,
                {
                    params:this.ajaxParam
                }
            );
         if(code===200){
             this.$refs.table.clearCheckboxRow();
             this.batchRecordRows=[];
             this.records = data.content;
             this.total = ~~data.totalElements;
         }
        },
        checkboxChange(row) {
            //多选操作
            this.batchRecordRows = row.records;
        },

    //    单个分配/取消
        async singleAssign(row){
            let {code} =   await  this.$api.post(`/oauth/server/escort/web/role/assignUser`,
                null,
                {
                    params:{
                        id:this.recordRow.id,
                        userId:row.userId
                    }
                }
            );
            if(code===200){
                this.queryList();
                this.$message({
                    message: '操作成功',
                    type: 'success',
                    center: true
                });
            }
        },

    //    批量分配
        async batAssign(relevance){
            let userId = this.batchRecordRows.map(item=>item.userId)
            let {code} =   await  this.$api.post(`/oauth/server/escort/web/role/assignUser/${this.recordRow.id}`,
                {
                        relevance:relevance,
                        userIds:userId
                }
            );
            if(code===200){
                this.queryList();
                this.$message({
                    message: '操作成功',
                    type: 'success',
                    center: true
                });
            }
        }
    }
}
</script>

<style scoped>

</style>