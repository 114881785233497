<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="角色名称" :required="true">
            <el-input v-model="form.roleName" class="formInput" />
          </el-form-item>
          <el-form-item label="角色编码" :required="true">
            <el-input v-model="form.roleCode" class="formInput" :disabled="form.isDefault==0&&info.addOr==='upd'" />
          </el-form-item>
          <el-form-item v-if="$admin" label="系统配置" :required="true" class="formInput">
            <el-select v-model="form.isDefault" class="w-100" :disabled="form.isDefault==0&&info.addOr==='upd'" @change="changeType">
              <el-option :value="0" label="是" />
              <el-option :value="1" label="否" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="refresh" :required="form.isDefault==1" label="公司">
            <el-select
              v-model="form.companyId"
              filterable
              :multiple="$admin&&form.isDefault==0"
              class="formInput w-100"
            >
              <el-option v-for="(v,i) in companyList" :key="v.id" :value="v.id" :label="v.companyName" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { company, role } from '@/api'
const hasSon = JSON.parse(sessionStorage.SonIds)
export default {
  name: 'Operate',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        roleName: '',
        roleCode: '',
        isDefault: this.$admin ? 0 : 1,
        companyId: [],
        isShow: 0
      },
      menu: [],
      companyList: [],
      hasSon,
      refresh: true
    }
  },
  created() {
    this.getCompanyList()
  },
  mounted() {
    this.upd()
  },
  methods: {
    upd() {
      if (this.info.addOr === 'upd') {
        this.$axiosReq(role + this.row.id, null, null, 'get').then(res => {
          this.form.roleName = res.data.roleName
          this.form.roleCode = res.data.roleCode
          this.form.isDefault = res.data.isDefault
          this.form.companyId = []
          if (this.form.isDefault == 0) {
            for (const i of this.row.companyIds) {
              this.form.companyId.push(i.companyId)
            }
          } else {
            for (const i of this.row.companyIds) {
              this.form.companyId = i.companyId
            }
          }
        })
      }
    },
    getCompanyList() {
      this.loading = true
      this.$axiosReq(company, null, {
        pageSize: 10000
      }, 'get').then(res => {
        this.companyList = res.data.content
      }).finally(e => {
        this.loading = false
      })
    },
    changeRoleG() {
      this.form.level = ''
    },
    changeType(v) {
      this.refresh = false
      setTimeout(() => { this.refresh = true }, 10)
      this.form.companyId = []
    },
    // 提交添加
    commit() {
      if (this.$textNull(this.form.roleName)) {
        this.$message.error('请填写角色名称！')
        return
      } else if (this.$textNull(this.form.roleCode)) {
        this.$message.error('请填写角色编码！')
        return
      }
      if (this.$admin) {
        if (this.form.isDefault == 1) {
          if (this.$textNull(this.form.companyId)) {
            this.$message.error('请选择公司！')
            return
          } else {
            if (this.form.companyId) {
            	this.form.companyId = [this.form.companyId]
            } else{
				this.form.companyId = []
			}
          }
        }
      } else {
		  if (this.form.companyId) {
		  	this.form.companyId = [this.form.companyId]
		  } else{
				this.form.companyId = []
		  }
      }
      if (this.info.addOr === 'add') {
        this.$axiosReq(role, this.form, null, 'post').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      } else {
        this.form.id = this.row.id
        this.$axiosReq(role, this.form, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
