<template>
    <el-select size="mini"
               v-bind="$attrs"
               v-on="$listeners"
               placeholder="请选择公司" clearable
               filterable
              >
        <el-option
                v-for="item in options"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "jobStatusRadioGroup",
    props:{
        recordRow:{
            type:Object,
            default:()=>null
        }
    },
    created() {
        this.queryList();
    },
    data(){
        return{
            options:[]
        }
    },
    methods:{
        async queryList(){
            let {code, data} = await this.$api.get(`/oauth/server/escort/web/company/role/${this.recordRow.id}`);
            if(code === 200){
                this.options = data;
            }
        }
    }
}
</script>

<style scoped>

</style>
